// This is used to dynamically show/hide the navbar
// On qreate pages, we do not want to show the navbar
// On in-app pages, we do want to show the navbar
import React, { createContext, useContext, useState } from 'react';

type NavBarContextType = {
  hideNavBar: boolean;
  setHideNavBar: (hide: boolean) => void;
};

const NavBarContext = createContext<NavBarContextType | undefined>(undefined);

export const useNavBarContext = () => {
  const context = useContext(NavBarContext);
  if (!context) {
    throw new Error("useNavBarContext must be used within a NavBarProvider");
  }
  return context;
};

export const NavBarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [hideNavBar, setHideNavBar] = useState(false);

  return (
    <NavBarContext.Provider value={{ hideNavBar, setHideNavBar }}>
      {children}
    </NavBarContext.Provider>
  );
};