import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import client from './client'; // Import your axios client
import { QreateCreateSchema, QreateResultSchemaOwner, QreateUnlockInputSchema, QreateUnlockResultSchema, QreateUpdateSchema, QreateResultSchemaMedia, QreateContentsResultSchema, QreateResultSchemaFeedback } from '../schemas/qreate';

// Get all Qreates of the current user
export const useGetAllQreate = () => {
  return useQuery({
    queryKey: ['qreate'],
    queryFn: async () => {
      const response = await client.get<QreateResultSchemaOwner[]>('/qreate');
      return response.data;
    },
  });
};

// Define a generic hook to handle common logic
const useGetQreateContent = <T,>(qrCode: string | undefined, endpoint: string) => {
  return useQuery({
    queryKey: [qrCode, endpoint],
    queryFn: async () => {
      if (!qrCode) {
        throw new Error('QR Code is undefined');
      }
      const token = localStorage.getItem(`token_${qrCode}`);
      const response = await client.get<T>(`/qreate/${qrCode}/${endpoint}`, {
        headers: {
          "qr-access-token": token,
        },
      });
      return response.data;
    },
    retry: false, // Do not retry if a passcode is required or any other 403 error occurs
  });
};

// Use the generic hook for video content
export const useGetQreateVideo = (qrCode: string | undefined) => {
  return useGetQreateContent<QreateResultSchemaMedia>(qrCode, 'video');
};
// Use the generic hook for document content
export const useGetQreateDocument = (qrCode: string | undefined) => {
  return useGetQreateContent<QreateResultSchemaMedia>(qrCode, 'document');
};
// Use the generic hook for image content
export const useGetQreateImage = (qrCode: string | undefined) => {
  return useGetQreateContent<QreateResultSchemaMedia>(qrCode, 'image');
};
// Use the generic hook for audio content
export const useGetQreateAudio = (qrCode: string | undefined) => {
  return useGetQreateContent<QreateResultSchemaMedia>(qrCode, 'audio');
};

// Use the generic hook for feedback content
export const useGetQreateFeedback = (qrCode: string | undefined) => {
  return useGetQreateContent<QreateResultSchemaFeedback>(qrCode, 'feedback');
};

// Get a single Qreate by its code - used for owners
export const useGetQreate = (qrCode: string | undefined) => {
  return useQuery({
    queryKey: [qrCode, "edit"],
    queryFn: async () => {
      const response = await client.get<QreateResultSchemaOwner>(`/qreate/${qrCode}/edit`);
      return response.data;
    },
  });
};

export const useGetQreateContents = (qrCode: string | undefined) => {
  return useQuery({
    queryKey: [qrCode, "contents"],
    queryFn: async () => {
      const response = await client.get<QreateContentsResultSchema[]>(`/qreate/${qrCode}/contents`);
      return response.data;
    },
  });
}

export const useUnlockQreate = (qrCode: string) => {
  return useMutation({
    mutationFn: async (passcode: QreateUnlockInputSchema) => {
      const response = await client.post<QreateUnlockResultSchema>(`/qreate/${qrCode}/unlock`, passcode);
      return response.data;
    },
    onSuccess: (data) => {
      // Store the token in local storage
      localStorage.setItem(`token_${qrCode}`, data.token);
    },
  });
}

// Create a new Qreate
export const useCreateQreate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: QreateCreateSchema) => {
      const response = await client.post<QreateResultSchemaOwner>('/qreate', data);
      return response.data;
    },
    onSuccess: (newQreate) => {
      // Update the cache for the ['qreate'] query with the new media item
      queryClient.setQueryData<QreateResultSchemaOwner[]>(['qreate'], (oldQreates) => {
        return oldQreates ? [...oldQreates, newQreate] : [newQreate];
      });
    },
  });
};

// Update a Qreate
export const useUpdateQreate = (qrCode: string | undefined) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: QreateUpdateSchema) => {
      const response = await client.put<QreateResultSchemaOwner>(`/qreate/${qrCode}`, data);
      return response.data;
    },
    onSuccess: (updatedQreate) => {
      // Update the cache for the ['qreate'] query with the updated qreate item
      queryClient.setQueryData<QreateResultSchemaOwner[]>(['qreate'], (oldQreates) => {
        return oldQreates?.map(qreate =>
          qreate.uuid === updatedQreate.uuid ? updatedQreate : qreate
        );
      });
      queryClient.invalidateQueries({ queryKey: [qrCode] });
    },
  });
};

// Update QR Code Status
export const useUpdateQreateStatus = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: { qrCode: string, action: "activate" | "deactivate" }) => {
      const { qrCode, action } = params;
      const endpoint = action === "deactivate" ? `/qreate/${qrCode}/deactivate` : `/qreate/${qrCode}/activate`;
      const response = await client.put<QreateResultSchemaOwner>(endpoint);
      return response.data;
    },
    onSuccess: (updatedQreate) => {
      queryClient.setQueryData<QreateResultSchemaOwner[]>(['qreate'], (oldQreates) => {
        return oldQreates?.map(qreate =>
          qreate.uuid === updatedQreate.uuid ? updatedQreate : qreate
        );
      });
    },
  });
};

export const useDeleteQreate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (qrCode: string) => {
      await client.delete(`/qreate/${qrCode}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['qreate'] });
    },
  });
}