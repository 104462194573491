import React from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Divider,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useRegister } from '../queries/auth'; // Adjust the path to your useRegister hook
import { UserCreateSchema } from '../schemas/auth'; // Ensure this path is correct
import { getErrorMessage } from '../utils/utils';
import GoogleLoginButton from '../components/login/google';

const RegisterPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<UserCreateSchema>();

  const { mutate: registerUser, isPending, isError, error } = useRegister();

  // Check if passwords match
  const password = watch('password');

  // Handle form submission
  const onSubmit: SubmitHandler<UserCreateSchema> = (data) => {
    registerUser(data);
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" gutterBottom>
        Register
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <TextField
          label="First Name"
          variant="outlined"
          {...register('first_name', { required: 'First name is required' })}
          error={!!errors.first_name}
          helperText={errors.first_name?.message}
        />
        <TextField
          label="Last Name"
          variant="outlined"
          {...register('last_name', { required: 'Last name is required' })}
          error={!!errors.last_name}
          helperText={errors.last_name?.message}
        />
        <TextField
          label="Email"
          variant="outlined"
          {...register('email', { required: 'Email is required' })}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          {...register('password', { required: 'Password is required' })}
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        <TextField
          label="Confirm Password"
          type="password"
          variant="outlined"
          {...register('password_confirm', {
            required: 'Please confirm your password',
            validate: (value) =>
              value === password || 'Passwords do not match',
          })}
          error={!!errors.password_confirm}
          helperText={errors.password_confirm?.message}
        />
        {isError && (
          <Alert severity="error">{getErrorMessage(error)}</Alert>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isPending}
        >
          {isPending ? <CircularProgress size={24} /> : 'Register'}
        </Button>
        <Divider>
          Or
        </Divider>
        <GoogleLoginButton
          onSuccess={(credential) => {
            console.log('Google login succeeded:', credential);
          }}
          onError={() => {
            console.log('Google login failed');
          }}
        />
      </Box>
    </Container>
  );
};

export default RegisterPage;