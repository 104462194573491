import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';  // Import useParams
import { Container, Typography } from '@mui/material';
import ContentPageDocument from './mediaContent/contentDocument';
import ContentPageVideo from './mediaContent/contentVideo';
import ContentFeedbackPage from './feedback/contentFeedback';
import { useNavBarContext } from '../../utils/contextNavbar';
interface ComponentMap {
  [key: string]: React.ComponentType<any>;
}

export const componentMap: ComponentMap = {
  A: ContentPageDocument,
  B: ContentPageVideo,
  // Add other mappings as needed
  E: ContentFeedbackPage,
};

const ContentRedirect: React.FC = () => {
  const { qrCode } = useParams<{ qrCode: string }>();
  const { setHideNavBar } = useNavBarContext();

  useEffect(() => {
    // Hide the navbar when this component is loaded
    setHideNavBar(true);

    // Restore the navbar when the component is unmounted
    return () => {
      setHideNavBar(false);
    };
  }, [setHideNavBar]);

  if (!qrCode) {
    return (
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          QR code is missing
        </Typography>
      </Container>
    );
  }

  const firstLetter = qrCode.charAt(0);
  const ComponentToRender = componentMap[firstLetter] || null;

  return (
    <Container maxWidth="md">
      {ComponentToRender ? (
        <ComponentToRender qrCode={qrCode} />
      ) : (
        <Typography variant="h4" gutterBottom>
          Page not valid
        </Typography>
      )}
    </Container>
  );
}

export default ContentRedirect;