import { FeedbackResultSchema } from "./feedback";
import { MediaResultSchema } from "./media";

export enum QrType {
    DOCUMENT = "document",
    VIDEO = "video",
    IMAGE = "image",
    AUDIO = "audio",
    FEEDBACK = "feedback"
}

export enum QrStatus {
    ACTIVE = "active",
    INACTIVE = "inactive"
}

// Result schema for visitor of Qreate
export interface QreateResultSchema {
    uuid: string;
    qr_code: string;
    title: string;
    qr_type: QrType;
    content_uuid?: string;
}
// Result schema for visitor of Qreate
export interface QreateResultSchemaMedia extends QreateResultSchema {
    content?: MediaResultSchema;
}

export interface QreateResultSchemaFeedback extends QreateResultSchema {
    content?: FeedbackResultSchema;
}

// Result schema for owner of Qreate
export interface QreateResultSchemaOwner extends QreateResultSchema {
    expiry_date?: string;
    status: QrStatus;
    is_locked: boolean;
}

export interface QreateContentsResultSchema {
    uuid: string;
    title: string;
}


export interface QreateCreateSchema {
    title: string;
    qr_type: QrType;
}

export interface QreateUpdateSchema {
    title?: string;
    content_uuid?: string;
    status?: QrStatus;
    passcode_protect?: boolean;
    passcode?: string;
    passcode_confirm?: string;
}

export interface QreateUnlockInputSchema {
    passcode: string;
}

export interface QreateUnlockResultSchema {
    token: string;
}