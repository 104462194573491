import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Switch, FormControlLabel, Typography } from '@mui/material';
import { QreateUpdateSchema } from '../../schemas/qreate';
import { useGetQreate, useUpdateQreate } from '../../queries/qreate';


interface UpdateQreateModalProps {
  qrCode: string;
  open: boolean;
  onClose: () => void;
}

const UpdateQreateModal: React.FC<UpdateQreateModalProps> = ({ qrCode, open, onClose }) => {
  const { data: qreate, isLoading: isQreateLoading } = useGetQreate(qrCode); // Fetch the qreate data
  const { mutate: updateQreate, isPending, isError, error } = useUpdateQreate(qrCode);

  // Set up react-hook-form
  const { handleSubmit, control, watch, formState: { errors }, reset } = useForm<QreateUpdateSchema>();

  useEffect(() => {
    if (qreate) {
      const resetData = {
        passcode_protect: qreate.is_locked, // Provide a default value
      };
      reset(resetData);
    }
  }, [qreate, reset]);

  const handleOnClose = () => {
    reset();
    onClose();
  }

  const passcodeProtectValue = watch('passcode_protect'); // Watch passcode_protect to show/hide passcode fields

  // Form submission handler
  const onSubmit = (data: QreateUpdateSchema) => {
    if (data.passcode !== data.passcode_confirm) {
      alert("Passcodes do not match");
      return;
    }
    updateQreate(data);
    // Close modal on success
    if (!isPending && !isError) {
      handleOnClose();
    }
  };

  if (isQreateLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <DialogTitle>Protect Qreate</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {/* Passcode Protect Switch */}
          <FormControlLabel
            control={
              <Controller
                name="passcode_protect"
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Passcode Protect"
          />

          {/* Passcode and Passcode Confirm fields (show only if passcode_protect is true) */}
          <>
            {/* Passcode Input */}
            <Controller
              name="passcode"
              control={control}
              rules={{
                required: passcodeProtectValue ? 'Passcode is required' : false,  // Conditional validation
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Passcode"
                  fullWidth
                  type="password"
                  margin="dense"
                  error={!!errors.passcode}
                  helperText={errors.passcode?.message}
                  disabled={!passcodeProtectValue}
                />
              )}
            />

            {/* Passcode Confirm Input */}
            <Controller
              name="passcode_confirm"
              control={control}
              rules={{
                required: passcodeProtectValue ? 'Passcode is required' : false,  // Conditional validation
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Confirm Passcode"
                  fullWidth
                  type="password"
                  margin="dense"
                  error={!!errors.passcode_confirm}
                  helperText={errors.passcode_confirm?.message}
                  disabled={!passcodeProtectValue}
                />
              )}
            />
          </>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleOnClose} color="secondary">Cancel</Button>
          <Button type="submit" color="primary" disabled={isPending}>
            {isPending ? "Updating..." : "Update"}
          </Button>
        </DialogActions>
      </form>
      {isError && <p style={{ color: 'red' }}>Error: {error?.message}</p>}
    </Dialog>
  );
};

export default UpdateQreateModal;