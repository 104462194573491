import {
  Button,
  Container,
  Grid2,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticatedQuery } from "../queries/auth";


// Function to create grid items
function createGridItem(label: string, onClick: () => void) {
  return (
    <Grid2 component="div" key={label}>
      <Button variant="contained" color="primary" fullWidth onClick={onClick}>
        {label}
      </Button>
    </Grid2>
  );
}

const Home: React.FC = () => {
  const navigate = useNavigate();

  const { data: isAuthenticated, } = useIsAuthenticatedQuery();


  // Convenience function to navigate to a path
  const navTo = (path: string) => {
    return () => navigate(path);
  };

  // If the user is authenticated, redirect to the dashboard
  if (isAuthenticated) {
    return (
      <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>
        <h2 className="text-center mb-4">Qreate with QR</h2>
        <Grid2 container direction="column" spacing={2}>
          {createGridItem("My media", navTo("/media"))}
          {createGridItem("My QRs", navTo("/qreate"))}
        </Grid2>
      </Container>
    );
  }
  return (
    <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>
      <h2 className="text-center mb-4">Qreate with QR</h2>
      <Grid2 container direction="column" spacing={2}>
        {createGridItem("Log In", navTo("/login"))}
        {createGridItem("Register", navTo("/register"))}
      </Grid2>
    </Container>
  );
};

export default Home;