import React from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Link,
  Divider,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useLogin } from '../queries/auth'; // Adjust the path to your useLogin hook
import { UserLoginSchema } from '../schemas/auth';
import { getErrorMessage } from '../utils/utils';
import GoogleLoginButton from '../components/login/google';


const LoginPage: React.FC = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<UserLoginSchema>();
  const { mutate: login, isPending, isError, error } = useLogin();

  // Handle form submission
  const onSubmit: SubmitHandler<UserLoginSchema> = (data) => {
    login(data);
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <TextField
          label="Email"
          variant="outlined"
          {...register('email', { required: 'Email is required' })}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          {...register('password', { required: 'Password is required' })}
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        {isError && (
          <Alert severity="error">{getErrorMessage(error)}</Alert>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isPending}
        >
          {isPending ? <CircularProgress size={24} /> : 'Login'}
        </Button>
        {/* Add link to reset password page */}
        <Typography align="right" variant="body2" sx={{ marginTop: 0 }}>
          <Link href="/request_password_reset" underline="hover">
            Forgot your password?
          </Link>
        </Typography>
        <Divider>
          Or
        </Divider>
        <GoogleLoginButton
          onSuccess={(credential) => {
            console.log('Google login succeeded:', credential);
          }}
          onError={() => {
            console.log('Google login failed');
          }}
        />
      </Box>
    </Container>
  );
};

export default LoginPage;