import React, { useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { Button, Box, Typography } from '@mui/material';

interface QRCodeGeneratorProps {
  qrCode: string;
}

const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({ qrCode }) => {
  const qrCodeRef = useRef<HTMLCanvasElement>(null);

  const handleDownload = () => {
    const canvas = qrCodeRef.current;
    if (canvas) {
      const url = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = url;
      link.download = `${qrCode}.png`;
      link.click();
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" sx={{
      minHeight: 200, // Set the minimum height here
      maxHeight: '100%', // Cap the height at the parent's height
      overflow: 'hidden', // Ensure it does not exceed the parent's bounds
    }}>
      <QRCodeCanvas ref={qrCodeRef} value={qrCode} size={150} />
      <Typography variant="body2" style={{ marginTop: '10px' }}>
        {qrCode}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleDownload}
        fullWidth
        style={{ marginTop: '10px' }}
      >
        Download
      </Button>
    </Box>
  );
};

export default QRCodeGenerator;