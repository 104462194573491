import axios from "axios";

// Safely extract error message
export const getErrorMessage = (error: unknown): string => {
    if (axios.isAxiosError(error)) {
        return error.response?.data?.message || 'An unknown error occurred';
    }
    return 'An unknown error occurred';
};

export const getErrorStatus = (error: unknown): number => {
    if (axios.isAxiosError(error)) {
        return error.response?.status || 500;
    }
    return 500;
}

export const getQreateLink = (qrCode: string): string => {
    return `${window.location.origin}/qreate/${qrCode}`;
}