import axios, { AxiosResponse, AxiosError, InternalAxiosRequestConfig, AxiosHeaders } from 'axios';

// Define the shape of your token response
interface RefreshTokenResponse {
  jwt: string;
  refresh: string;
  token_type: string;
}

// Extend AxiosRequestConfig to include _retry
interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean; // Optional property
}

// Create an axios instance
const client = axios.create({
  baseURL: '/api', // Your API base URL
});

// Define authentication routes that should bypass token refresh logic
// TODO: How to avoid retries on 'public' routes?
const authRoutes = ['/auth/refresh', '/auth/login'];


// Add a request interceptor
client.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('token');
    if (token) {
      // Ensure headers exist and are in the expected format
      if (!config.headers) {
        config.headers = new AxiosHeaders();
      }

      // Set the Authorization header using the 'set' method for AxiosHeaders
      (config.headers as AxiosHeaders).set('Authorization', `Bearer ${token}`);
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response: AxiosResponse) => {
    // Return a successful response back to the calling service
    return response;
  },
  async (error: AxiosError) => {
    // If there is an error, check if it's an expired token and if so, get a new one
    const originalRequest = error.config as CustomAxiosRequestConfig;
    const refreshToken = localStorage.getItem('refresh');

    // If the error status is 401 and it's not a token refresh request
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      !authRoutes.some(route => originalRequest.url?.includes(route)) && // Check if the URL is in the authRoutes
      refreshToken
    ) {
      originalRequest._retry = true;

      // Refresh the token using your refresh token endpoint
      try {
        const response = await client.post<RefreshTokenResponse>('/auth/refresh', {
          refresh: refreshToken,
        });
        const newAccessToken = response.data.jwt;

        // Update headers using the AxiosHeaders method
        if (originalRequest.headers) {
          (originalRequest.headers as AxiosHeaders).set('Authorization', `Bearer ${newAccessToken}`);
        } else {
          originalRequest.headers = new AxiosHeaders();
          (originalRequest.headers as AxiosHeaders).set('Authorization', `Bearer ${newAccessToken}`);
        }
        // Update the token in local storage
        localStorage.setItem('token', newAccessToken);

        // Retry the original request
        return client(originalRequest);
      } catch (refreshError) {
        // Handle the token refresh error, e.g., redirect to login page
        console.error('Token refresh failed', refreshError);
        // Redirect to the login page or do something else
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    // Check if the error status is 403 (forbidden), in which case do not retry
    if (error.response?.status === 403) {
      console.error('403 Forbidden: ', error.message);
      return Promise.reject(error);
    }

    // If it's not a 401 error or token refresh failed, just reject the promise
    return Promise.reject(error);
  }
);

export default client;
