import React from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FeedbackCreateSchema } from '../../../schemas/feedback';
import { getErrorMessage } from '../../../utils/utils';
import { useCreateFeedback } from '../../../queries/feedback';

interface FeedbackCreateModalProps {
  open: boolean;
  handleClose: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const FeedbackCreateModal: React.FC<FeedbackCreateModalProps> = ({ open, handleClose }) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm<FeedbackCreateSchema>();
  const { mutate: createFeedback, isPending, isError, error } = useCreateFeedback();

  const onSubmit: SubmitHandler<FeedbackCreateSchema> = (data) => {
    createFeedback(data, {
      onSuccess: () => {
        reset();
        handleClose(); // Close the modal on success
      },
      onError: (error) => {
        console.error('Error creating feedback:', error);
      },
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-feedback-title"
      aria-describedby="modal-feedback-description"
    >
      <Box sx={style}>
        <Typography variant="h6" component="h2" gutterBottom>
          Add New Feedback
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <TextField
            label="Title"
            variant="outlined"
            {...register('title', { required: 'Title is required' })}
            error={!!errors.title}
            helperText={errors.title?.message}
          />
          <TextField
            label="Description"
            variant="outlined"
            {...register('description')}
          />

          {isError && <Typography color="error">{getErrorMessage(error)}</Typography>}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isPending}  // Disable button while loading
          >
            {isPending ? 'Creating...' : 'Create'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FeedbackCreateModal;