import React, { useState } from 'react';
import {
    Tooltip,
    IconButton,
    Box,
} from '@mui/material';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridAddIcon } from '@mui/x-data-grid';
import MediaCreateModal from './media.createModal'; // Import the MediaCreateModal component
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQueryClient } from '@tanstack/react-query';

const CustomToolbar: React.FC = () => {
    const queryClient = useQueryClient();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleRefresh = () => {
        // This invalidats the query and forces a refetch
        queryClient.invalidateQueries({ queryKey: ['media'] });
        console.log('Refresh the data');
    }


    return (
        <>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={{ tooltip: { title: 'Change density' } }}
                />
                <Box sx={{ flexGrow: 1 }} />
                <Tooltip title="Refresh table">
                    <IconButton
                        color="primary"
                        onClick={handleRefresh} // Open the modal
                    >
                        {/* Add refresh icon */}
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Add new media">
                    <IconButton
                        color="primary"
                        onClick={handleOpen} // Open the modal
                        sx={{ mr: 2 }}
                    >
                        <GridAddIcon />
                    </IconButton>
                </Tooltip>
            </GridToolbarContainer>

            {/* Use the MediaCreateModal component */}
            <MediaCreateModal open={open} handleClose={handleClose} />
        </>
    );
};

export default CustomToolbar;