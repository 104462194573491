import React, { useEffect, useState } from 'react';
import { Typography, Alert, Container } from '@mui/material';
import UnlockQreateModal from './content.modalUnlock';
import { getErrorMessage, getErrorStatus } from '../../utils/utils';

interface ContentPageWrapperProps {
  title?: string | null | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  qrCode: string;
  children: React.ReactNode;
}

const ContentPageWrapper: React.FC<ContentPageWrapperProps> = ({
  title,
  isLoading,
  isError,
  error,
  qrCode,
  children
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleCloseModal = (isSuccess: boolean) => {
    setOpenModal(false);
    if (isSuccess) {
      // Handle the case when password is entered successfully
      window.location.reload();
    }
  };

  // Determine if the error is a 403 and needs the modal
  useEffect(() => {
    if (isError && getErrorStatus(error) === 403) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [isError, error]);

  return (
    <Container maxWidth="md">
      {/* Show the title */}
      {!isLoading && title && <Typography variant="h4" gutterBottom mt={2}>{title}</Typography>}

      {/* Show loading message */}
      {isLoading && <Typography>Loading...</Typography>}

      {/* Show error message if there is an error */}
      {isError && getErrorStatus(error) !== 403 && (
        <Alert severity="error">{getErrorMessage(error)}</Alert>
      )}

      {/* Show error message if there is an error */}
      {isError && getErrorStatus(error) === 403 && (
        <Alert severity="warning">
          This Qreate is passcode protected. Please enter the passcode to unlock it.
        </Alert>
      )}

      {/* Show the modal to unlock the Qreate */}
      <UnlockQreateModal
        qrCode={qrCode}
        open={openModal}
        handleClose={handleCloseModal}
      />

      {!isError && children}
    </Container>
  );
};

export default ContentPageWrapper;