import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, CircularProgress, Typography, Alert, Grid2, Tooltip, IconButton } from '@mui/material';
import { useIsAuthenticatedQuery } from '../../queries/auth';
import { useGetAllQreate } from '../../queries/qreate';
import QreateCard from './qreate.singleCard';
import { QreateResultSchemaOwner } from '../../schemas/qreate';
import QreateCreateModal from './qreate.modalNew';
import { GridAddIcon } from '@mui/x-data-grid';

const QreatePage: React.FC = () => {
  const navigate = useNavigate();
  const { data: isAuthenticated, isLoading: isAuthLoading } = useIsAuthenticatedQuery();
  const { data: qreate, isLoading: isQreateLoading, isError, error } = useGetAllQreate(); // Fetch qreate items

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  useEffect(() => {
    if (!isAuthLoading) {
      if (!isAuthenticated) {
        navigate('/login');
      }
    }
  }, [isAuthenticated, isAuthLoading, navigate]);

  if (isAuthLoading || isQreateLoading) {
    return <CircularProgress />;
  }


  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        My Qreates
      </Typography>
      {/* Add qreate modal */}
      <Tooltip title="Add new qreate">
        <IconButton
          color="primary"
          onClick={handleOpen}
          sx={{ mr: 2 }}
        >
          <GridAddIcon />
        </IconButton>
      </Tooltip>
      {isError && <Alert severity="error">{error.message}</Alert>}
      {qreate && (
        <Grid2 container spacing={3}>
          {qreate.map((q: QreateResultSchemaOwner) => (
            <Grid2 size={12} key={q.uuid}>
              <QreateCard qreate={q} />
            </Grid2>
          ))}
        </Grid2>
      )}
      <QreateCreateModal open={modalOpen} handleClose={handleClose} />
    </Container>
  );
};

export default QreatePage;