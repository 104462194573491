import React from 'react';
import { Container, Typography, Grid2, Alert, CircularProgress, Card, Box, CardHeader } from '@mui/material';
import { useGetAllFeedbackResponse, useGetFeedback } from '../../../queries/feedback';
import { FeedbackResponseResultSchema } from '../../../schemas/feedback';
import { useParams } from 'react-router-dom';

const FeedbackResponseOverview: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data: feedbackItem, isLoading: isLoadingItem, isError: isErrorItem, error: errorItem } = useGetFeedback(uuid);
  const { data: responses, isLoading: isLoadingResponses, isError: isErrorResonses, error: errorResponses } = useGetAllFeedbackResponse(uuid);

  if (isLoadingItem || isLoadingResponses) {
    return (
      <Container maxWidth="md">
        <CircularProgress />
      </Container>
    );
  }

  if (isErrorItem || isErrorResonses) {
    return (
      <Container maxWidth="md">
        {isErrorItem && <Alert severity="error">{errorItem.message}</Alert>}
        {isErrorResonses && <Alert severity="error">{errorResponses.message}</Alert>}
      </Container>
    );
  }

  return (
    <>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Feedback Overview for {feedbackItem?.title}
        </Typography>
        <Grid2 container spacing={3}>
          {responses?.map((feedback: FeedbackResponseResultSchema) => (
            <Grid2 key={feedback.uuid} size={12}>
              <Card sx={{ borderRadius: 2, maxWidth: 600 }}>
                <CardHeader
                  title={
                    <Box display="flex" alignItems="center">
                      <Typography variant="h6">{feedback.name}</Typography>
                    </Box>
                  }
                  subheader={`Score: ${feedback.score}`}
                />
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </Container>
    </>
  );
};

export default FeedbackResponseOverview;