import React, { useState } from 'react';
import { Card, CardContent, CardHeader, IconButton, Typography, Tooltip, Box, Button, Grid2 } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import { useNavigate } from 'react-router-dom';
import { QreateResultSchemaOwner } from '../../schemas/qreate';
import { useDeleteQreate, useUpdateQreateStatus } from '../../queries/qreate';
import UpdateQreateModal from './qreate.editPassword';
import QRCodeGenerator from './qreate.qrCode';

interface QreateCardProps {
  qreate: QreateResultSchemaOwner;
}

const QreateCard: React.FC<QreateCardProps> = ({ qreate }) => {
  const navigate = useNavigate();
  const { mutate: updateQreateStatus } = useUpdateQreateStatus();
  const { mutate: deleteQreate } = useDeleteQreate();

  const [qrCode, setQrCode] = useState<string>('');
  const [modalOpen, setModalOpen] = useState(false);

  const handleStatusChange = (action: "activate" | "deactivate") => {
    updateQreateStatus({ qrCode: qreate.qr_code, action });
  };

  const handleOpen = () => {
    // Open in new tab
    window.open(`/qreate/${qreate.qr_code}`, '_blank');
  };

  const handleOpenEdit = () => {
    navigate(`/qreate/${qreate.qr_code}/edit`);
  };

  const openModalWithQrCode = (qrCode: string) => {
    setQrCode(qrCode);
    setModalOpen(true);
  }

  const lockIcon = qreate.is_locked ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />;

  const OpenIcon: React.FC = () => {
    return (
      <Tooltip title="Open Qreate">
        <IconButton onClick={handleOpen}>
          <OpenInNewOutlinedIcon />
        </IconButton>
      </Tooltip>
    )
  }

  const LockIcon: React.FC = () => {
    const title = qreate.is_locked ? 'Unlock Qreate' : 'Lock Qreate';
    return (
      <Button startIcon={lockIcon} onClick={() => openModalWithQrCode(qreate.qr_code)}>
        {title}
      </Button>
    );
  }

  const EditIcon: React.FC = () => {
    return (
      <Button startIcon={<EditOutlinedIcon />} onClick={handleOpenEdit}>
        Edit qreate
      </Button>
    );
  };

  const OpenResultIcon: React.FC = () => {
    // TODO: This will apply for other types of qreate as well, find a better way to handle this
    if (qreate.qr_type === 'feedback') {
      return (
        <Button startIcon={<ListAltIcon />} onClick={() => navigate(`/feedback/${qreate.content_uuid}/response`)}>
          View responses
        </Button>
      );
    }
    return null;
  }

  const ChangeStatusIcon: React.FC = () => {
    const toggleIcon = qreate.status === 'active' ? <ToggleOnOutlinedIcon color="success" /> : <ToggleOffOutlinedIcon color="error" />;
    const action = qreate.status === 'active' ? 'deactivate' : 'activate';
    return (
      <Button startIcon={toggleIcon} onClick={() => handleStatusChange(action)}>
        {qreate.status === 'active' ? 'Active' : 'Inactive'}
      </Button>
    );
  };

  const DeleteActionIcon: React.FC = () => {
    return (
      <Button color='error' startIcon={<DeleteIcon />} onClick={() => deleteQreate(qreate.qr_code)}>
        Delete Qreate
      </Button>
    );
  };

  return (
    <>
      {qrCode && <UpdateQreateModal qrCode={qrCode} open={modalOpen} onClose={() => setModalOpen(false)} />}
      <Card sx={{ mb: 2, borderRadius: 2, maxWidth: 800 }}>
        <Grid2 container spacing={3}>
          <Grid2 size={{ xs: 12, sm: 7 }} >
            <CardHeader
              title={
                <Box display="flex" alignItems="center">
                  <Typography variant="h6">{qreate.title}</Typography>
                  <OpenIcon />
                </Box>
              }
              // subheader={`Type: ${qreate.qr_type} | Expires: ${qreate.expiry_date || 'N/A'}`}
              subheader={
                <Box display="flex" alignItems="center">
                  <Tooltip title="Content type">
                    <SellOutlinedIcon fontSize='small' sx={{ mr: 0.5 }} />
                  </Tooltip>
                  <Typography variant="body2" sx={{ mr: 2 }}>{qreate.qr_type}</Typography>
                  {"  "}
                  <Tooltip title="Expires at">
                    <EventBusyOutlinedIcon fontSize='small' sx={{ mr: 0.5 }} />
                  </Tooltip>
                  <Typography variant="body2">{qreate.expiry_date || "N/A"}</Typography>
                </Box>
              }
            />
            <CardContent sx={{ marginY: 0, paddingY: 0 }}>
              <Box display="flex" alignItems="center">
                <LockIcon />
              </Box>
              <Box display="flex" alignItems="center">
                <EditIcon />
              </Box>
              <Box display="flex" alignItems="center">
                <OpenResultIcon />
              </Box>
              <Box display="flex" alignItems="center">
                <ChangeStatusIcon />
              </Box>
              <Box display="flex" alignItems="center">
                <DeleteActionIcon />
              </Box>
            </CardContent>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 5 }}>
            <CardContent>
              <QRCodeGenerator qrCode={qreate.qr_code} />
            </CardContent>
          </Grid2>
        </Grid2>
      </Card >
    </>
  );
};

export default QreateCard;