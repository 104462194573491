import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, Pagination, Alert } from '@mui/material';
import { useGetQreateDocument } from '../../../queries/qreate';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import ContentPageWrapper from '../contentWrapper';
import { ContentPageProps } from '../schemas';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const ContentPageDocument: React.FC<ContentPageProps> = ({ qrCode }) => {
  const { data: qreate, isError, error, isLoading } = useGetQreateDocument(qrCode);
  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);


  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy) => {
    setNumPages(nextNumPages);
  };

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <ContentPageWrapper
      title={qreate?.title || 'Loading...'}
      isLoading={isLoading}
      isError={isError}
      error={error}
      qrCode={qrCode}
    >
      {qreate?.content?.url ? (
        <>
          <Document file={qreate?.content?.url} onLoadSuccess={onDocumentLoadSuccess}>
            <Box display="flex" justifyContent="center" mt={2}>
              <Page
                key={`page_${currentPage}`}
                pageNumber={currentPage}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                width={400}
              />
            </Box>
            <Box display="flex" justifyContent="center" mt={2}>
              <Pagination
                count={numPages}
                page={currentPage}
                onChange={(event, page) => onPageChange(page)}
              />
            </Box>
          </Document>
        </>
      ) : (
        <Alert severity="info">No media file available for display.</Alert>
      )}
    </ContentPageWrapper>
  );
};

export default ContentPageDocument;