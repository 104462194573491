import React from 'react';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';

import { createTheme, ThemeProvider } from '@mui/material/styles';




import './App.css';
import Home from './pages/home';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import MediaOverview from './pages/media/media';
import QreatePage from './pages/qreate/qreate';
import ContentRedirect from './pages/content/contentMapping';
import QreateUpdatePage from './pages/content/edit';
import TopNavBar from './components/navigation/navbar';
import PasswordResetRequestPage from './pages/passwordRequest';
import PasswordResetActionPage from './pages/passwordReset';
import { NavBarProvider, useNavBarContext } from './utils/contextNavbar';
import FeedbackOverview from './pages/content/feedback/feedback.overviewPage';
import FeedbackResponseOverview from './pages/content/feedback/feedback.responseOverviewPage';


const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 600000, // 10 minutes
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId="334538613797-qu716asv228ej5sfht0svv3tvflaj9kn">
          <QueryClientProvider client={queryClient}>
            <NavBarProvider>
              <Router>
                <NavBarHandler />
                <br />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/request_password_reset" element={<PasswordResetRequestPage />} />
                  <Route path="/password_reset" element={<PasswordResetActionPage />} />
                  <Route path="/register" element={<RegisterPage />} />
                  <Route path="/media" element={<MediaOverview />} />
                  <Route path="/feedback" element={<FeedbackOverview />} />
                  <Route path="/feedback/:uuid/response" element={<FeedbackResponseOverview />} />
                  <Route path="/qreate" element={<QreatePage />} />
                  <Route path='/qreate/:qrCode/edit' element={<QreateUpdatePage />} />
                  <Route path='/qreate/:qrCode' element={<ContentRedirect />} />
                </Routes>
              </Router>
            </NavBarProvider>
          </QueryClientProvider >
        </GoogleOAuthProvider>
      </ThemeProvider>
    </>
  );
}

// This component will handle showing/hiding the TopNavBar based on the context
const NavBarHandler = () => {
  const { hideNavBar } = useNavBarContext();
  return hideNavBar ? null : <TopNavBar />;
};

export default App;
