import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Typography, Box, Grid2, IconButton, TextField, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { FeedbackResultSchema } from '../../../schemas/feedback';
import { useDeleteFeedback, useUpdateFeedback } from '../../../queries/feedback';
import { useNavigate } from 'react-router-dom';

interface FeedbackCardProps {
  feedback: FeedbackResultSchema;
}

const FeedbackCard: React.FC<FeedbackCardProps> = ({ feedback }) => {
  const navigate = useNavigate();

  const { mutate: updateFeedback } = useUpdateFeedback(feedback.uuid);
  const { mutate: deleteFeedback } = useDeleteFeedback(feedback.uuid);
  const [isEditing, setIsEditing] = useState(false);
  const [editedFeedback, setEditedFeedback] = useState({
    title: feedback.title,
    description: feedback.description,
  });
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteClick = () => {
    deleteFeedback();
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleResponsesClick = () => {
    navigate(`/feedback/${feedback.uuid}/response`);
  }

  const handleCancelClick = () => {
    setEditedFeedback({ title: feedback.title, description: feedback.description });
    setIsEditing(false);
  }

  const handleSaveClick = () => {
    updateFeedback({ uuid: feedback.uuid, ...editedFeedback });
    setIsEditing(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedFeedback((prev) => ({ ...prev, [name]: value }));
  };

  const DeleteWithConfirmationButton = () => {
    if (isDeleting) {
      return (
        <Box display="flex" alignItems="center">
          <Typography variant="body2">Are you sure?</Typography>
          <Button onClick={handleDeleteClick}>Yes</Button>
          <Button onClick={() => setIsDeleting(false)}>No</Button>
        </Box>
      );
    }
    return (
      <IconButton color='error' onClick={() => setIsDeleting(true)}>
        <DeleteIcon />
      </IconButton>
    );
  }

  const EditableData = () => {
    if (isEditing) {
      return (
        <>
          <CardContent>
            <TextField
              variant="outlined"
              name="title"
              label="Title"
              value={editedFeedback.title}
              onChange={handleInputChange}
              fullWidth
              margin='dense'
            />
            <TextField
              variant="outlined"
              name="description"
              label="Description"
              value={editedFeedback.description || ''}
              onChange={handleInputChange}
              fullWidth
              multiline
              margin='dense'
            />
          </CardContent>
        </>
      );
    }
    return (
      <>
        <CardHeader
          title={
            <Box display="flex" alignItems="center">
              <Typography variant="h6">{feedback.title}</Typography>
            </Box>
          }
        />
        {feedback.description && (
          <CardContent>
            <Typography variant="body2">{feedback.description}</Typography>
          </CardContent>
        )}
      </>
    );
  }


  return (
    <Card sx={{ borderRadius: 2, maxWidth: 600 }}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <EditableData />

          {/* Stats */}
          <CardContent>
            <Box display="flex" alignItems="center">
              <Typography variant="body2">Responses: {10}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body2">Score: {4.5}</Typography>
            </Box>
          </CardContent>

          {/* Actions */}
          <CardContent>
            <Box display="flex" alignItems="center">
              {isEditing ? (
                <>
                  <IconButton onClick={handleSaveClick}>
                    <SaveIcon />
                  </IconButton>
                  <IconButton onClick={handleCancelClick}>
                    <CancelOutlinedIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton onClick={handleEditClick}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={handleResponsesClick}>
                    <ListAltIcon />
                  </IconButton>
                  <DeleteWithConfirmationButton />
                </>
              )}
            </Box>
          </CardContent>
        </Grid2>
      </Grid2>
    </Card>
  );
};

export default FeedbackCard;