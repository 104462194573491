import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import client from './client'; // Import your axios client
import { FeedbackCreateSchema, FeedbackResponseCreateSchema, FeedbackResponseResultSchema, FeedbackResultSchema } from '../schemas/feedback';


export const useGetAllFeedback = () => {
  return useQuery({
    queryKey: ['feedback'],
    queryFn: async () => {
      const response = await client.get<FeedbackResultSchema[]>('/feedback');
      return response.data;
    },
  });
};

export const useGetFeedback = (uuid?: string) => {
  return useQuery({
    queryKey: ['feedback', uuid],
    queryFn: async () => {
      const response = await client.get<FeedbackResultSchema>(`/feedback/${uuid}`);
      return response.data;
    },
  });
};

export const useCreateFeedback = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (feedback: FeedbackCreateSchema) => {
      const response = await client.post<FeedbackResultSchema>('/feedback', feedback);
      return response.data;
    },
    onSuccess: (newFeedback) => {
      // Update the cache for the ['feedback'] query with the new media item
      queryClient.setQueryData<FeedbackResultSchema[]>(['feedback'], (oldFeedback) => {
        return oldFeedback ? [...oldFeedback, newFeedback] : [newFeedback];
      });
    },
  });
};

export const useUpdateFeedback = (uuid: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (feedback: FeedbackResultSchema) => {
      const response = await client.put<FeedbackResultSchema>(`/feedback/${feedback.uuid}`, feedback);
      return response.data;
    },
    onSuccess: (updatedFeedback) => {
      // Update the cache for the ['feedback'] query with the updated media item
      queryClient.setQueryData<FeedbackResultSchema[]>(['feedback'], (oldFeedback) => {
        return oldFeedback ? oldFeedback.map((feedback) => {
          return feedback.uuid === updatedFeedback.uuid ? updatedFeedback : feedback;
        }) : [updatedFeedback];
      });
    },
  });
};

export const useDeleteFeedback = (uuid: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await client.delete(`/feedback/${uuid}`);
    },
    onSuccess: () => {
      // Update the cache for the ['feedback'] query by removing the deleted media item
      queryClient.setQueryData<FeedbackResultSchema[]>(['feedback'], (oldFeedback) => {
        return oldFeedback ? oldFeedback.filter((feedback) => feedback.uuid !== uuid) : [];
      });
    },
  });
}

export const useCreateFeedbackResponse = (uuid?: string) => {
  return useMutation({
    mutationFn: async (data: FeedbackResponseCreateSchema) => {
      const response = await client.post<boolean>(`/feedback/${uuid}/response`, data);
      return response.data;
    },
  });
}

export const useGetAllFeedbackResponse = (uuid?: string) => {
  return useQuery({
    queryKey: ['feedback', uuid, 'responses'],
    queryFn: async () => {
      const response = await client.get<FeedbackResponseResultSchema[]>(`/feedback/${uuid}/response`);
      return response.data;
    },
  });
}