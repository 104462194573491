import React from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { getErrorMessage } from '../../utils/utils';
import { QreateUnlockInputSchema } from '../../schemas/qreate';
import { useUnlockQreate } from '../../queries/qreate';

interface UnlockModalProps {
  qrCode: string;
  open: boolean;
  handleClose: (isSuccess: boolean) => void; // Accept a boolean argument
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const UnlockQreateModal: React.FC<UnlockModalProps> = ({ qrCode, open, handleClose }) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm<QreateUnlockInputSchema>();
  const { mutate: unlockQreate, isPending, isError, error } = useUnlockQreate(qrCode);

  const onSubmit: SubmitHandler<QreateUnlockInputSchema> = (data) => {
    unlockQreate(data, {
      onSuccess: () => {
        reset();
        handleClose(true); // Indicate success
      },
    });
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose(false)} // Close the modal without success
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h6" component="h2" gutterBottom>
          Unlock Qreate
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <TextField
            label="Passcode"
            type="password"
            variant="outlined"
            {...register('passcode', {
              required: 'Passcode is required', validate: {
                length: value => value.length === 6 || 'Passcode must be 6 digits long'
              }
            })}
            error={!!errors.passcode}
            helperText={errors.passcode?.message}
          />
          {isError && <Typography color="error">{getErrorMessage(error)}</Typography>}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isPending} // Disable button while loading
          >
            {isPending ? 'Unlocking...' : 'Unlock'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UnlockQreateModal;