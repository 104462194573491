import React, { useState } from 'react';
import { Container, Typography, Grid2, Alert, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useGetAllFeedback } from '../../../queries/feedback';
import { FeedbackResultSchema } from '../../../schemas/feedback';
import FeedbackCard from './feedback.singleCard';
import { GridAddIcon } from '@mui/x-data-grid';
import FeedbackCreateModal from './feedback.createModal';

const FeedbackOverview: React.FC = () => {
  const { data: feedbacks, isLoading, isError, error } = useGetAllFeedback();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  if (isLoading) {
    return (
      <Container maxWidth="md">
        <CircularProgress />
      </Container>
    );
  }

  if (isError) {
    return (
      <Container maxWidth="md">
        <Alert severity="error">{error.message}</Alert>
      </Container>
    );
  }

  return (
    <>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Feedback Overview
        </Typography>
        <Typography variant="body2" gutterBottom>
          This page contains all the feedback forms you have setup. These can all be used on feedback pages.
        </Typography>
        <Tooltip title="Add new feedback page">
          <IconButton
            color="primary"
            onClick={handleOpen}
            sx={{ mr: 2 }}
          >
            <GridAddIcon />
          </IconButton>
        </Tooltip>
        <Grid2 container spacing={3}>
          {feedbacks?.map((feedback: FeedbackResultSchema) => (
            <Grid2 key={feedback.uuid} size={12}>
              <FeedbackCard feedback={feedback} />
            </Grid2>
          ))}
        </Grid2>
      </Container>
      <FeedbackCreateModal open={modalOpen} handleClose={handleClose} />
    </>
  );
};

export default FeedbackOverview;