import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import client from './client'; // Import your axios client
import { AuthLoginResponseSchema, IsAuthenticatedResponseSchema, UserCreateSchema, UserGoogleLoginSchema, UserLoginSchema, UserRequestResetSchema, UserResetPasswordSchema } from '../schemas/auth';
import { useNavigate } from 'react-router-dom';

export const useRegister = () => {
  return useMutation<AuthLoginResponseSchema, unknown, UserCreateSchema>({
    mutationKey: ['auth', 'register'],
    mutationFn: async (userData: UserCreateSchema) => {
      const response = await client.post<AuthLoginResponseSchema>('/auth/register', userData);
      return response.data; // Extract data from Axios response
    },
    onSuccess: (data: AuthLoginResponseSchema) => {
      // Store the tokens in local storage
      localStorage.setItem('token', data.jwt);
      localStorage.setItem('refresh', data.refresh);
      window.location.assign('/'); // Redirect to the home page
    }
  });
}

export const useLogin = () => {
  return useMutation<AuthLoginResponseSchema, unknown, UserLoginSchema>({
    mutationKey: ['auth', 'login'],
    mutationFn: async (loginData: UserLoginSchema) => {
      const response = await client.post<AuthLoginResponseSchema>('/auth/login', loginData);
      return response.data; // Extract data from Axios response
    },
    onSuccess: (data: AuthLoginResponseSchema) => {
      localStorage.setItem('token', data.jwt);
      localStorage.setItem('refresh', data.refresh);
      window.location.assign('/'); // Redirect to the home page
    }
  });
};

export const useGoogleLogin = () => {
  return useMutation<AuthLoginResponseSchema, unknown, UserGoogleLoginSchema>({
    mutationKey: ['auth', 'googleLogin'],
    mutationFn: async (data: UserGoogleLoginSchema) => {
      const response = await client.post<AuthLoginResponseSchema>('/auth/google_login', data);
      return response.data;
    },
    onSuccess: (data: AuthLoginResponseSchema) => {
      localStorage.setItem('token', data.jwt);
      localStorage.setItem('refresh', data.refresh);
      window.location.assign('/');
    }
  });
}

export const useRequestResetMutation = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ['auth', 'requestReset'],
    mutationFn: async (data: UserRequestResetSchema) => {
      await client.post('/auth/request_password_reset', data);
    },
    onSuccess: () => {
      setTimeout(() => {
        navigate("/");
      }, 5000)
    },
  });
}

export const useResetPasswordMutation = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ['auth', 'resetPassword'],
    mutationFn: async (data: UserResetPasswordSchema) => {
      await client.post('/auth/password_reset', data);
    },
    onSuccess: () => {
      setTimeout(() => {
        navigate("/");
      }, 5000)
    },
  });
}

export const useIsAuthenticatedQuery = () => {
  return useQuery<boolean>({
    queryKey: ['auth', 'isAuthenticated'],
    retry: 0,
    queryFn: async () => {
      const response = await client.get<IsAuthenticatedResponseSchema>('/auth/is_auth');
      return response.data.is_authenticated
    },
  });
};

export const useLogoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return client.delete("/auth/logout");
    },
    onSuccess: () => {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      queryClient.invalidateQueries({ queryKey: ['auth'] });
      window.location.assign('/');
    },
  });
}
