import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '../../queries/auth'; // Adjust the path to your useGoogleLogin hook

interface GoogleLoginButtonProps {
  onSuccess?: (credential: string) => void;
  onError?: () => void;
}

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({ onSuccess, onError }) => {
  const { mutate: googleLogin } = useGoogleLogin();

  return (
    <GoogleLogin
      onSuccess={credentialResponse => {
        if (!credentialResponse.credential) {
          console.log('Login Failed');
          if (onError) onError(); // Call the error callback if provided
          return;
        }

        googleLogin({ token: credentialResponse.credential });

        if (onSuccess) onSuccess(credentialResponse.credential); // Call the success callback if provided
      }}
      onError={() => {
        console.log('Login Failed');
        if (onError) onError(); // Call the error callback if provided
      }}
    />
  );
};

export default GoogleLoginButton;