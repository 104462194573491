import React, { useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useResetPasswordMutation } from '../queries/auth'; // Adjust the path to your useLogin hook
import { UserResetPasswordSchema } from '../schemas/auth';
import { getErrorMessage } from '../utils/utils';
import { useNavigate } from 'react-router-dom';


const PasswordResetActionPage: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const { register, handleSubmit, formState: { errors }, watch } = useForm<UserResetPasswordSchema>();
  const { mutate: requestReset, isPending, isError, error, isSuccess } = useResetPasswordMutation();

  const navigate = useNavigate();

  const password = watch('password');

  useEffect(() => {
    // If there is no token, redirect to login
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token])

  // Handle form submission
  const onSubmit: SubmitHandler<UserResetPasswordSchema> = (data) => {
    // Add token to the data - token should be available, since we redirect if it's not
    if (token) {
      data.token = token;
      requestReset(data);
    }
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" gutterBottom>
        Reset password
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          {...register('password', { required: 'Password is required' })}
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        <TextField
          label="Confirm Password"
          type="password"
          variant="outlined"
          {...register('password_confirm', {
            required: 'Please confirm your password',
            validate: (value) =>
              value === password || 'Passwords do not match',
          })}
          error={!!errors.password_confirm}
          helperText={errors.password_confirm?.message}
        />
        {isError && (
          <Alert severity="error">{getErrorMessage(error)}</Alert>
        )}
        {isSuccess && (
          <Alert severity="info">Password reset successfully! You will be redirected.</Alert>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isPending}
        >
          {isPending ? <CircularProgress size={24} /> : 'Reset password'}
        </Button>
      </Box>
    </Container>
  );
};

export default PasswordResetActionPage;