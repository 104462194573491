import React from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Link,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useRequestResetMutation } from '../queries/auth'; // Adjust the path to your useLogin hook
import { UserRequestResetSchema } from '../schemas/auth';
import { getErrorMessage } from '../utils/utils';


const PasswordResetRequestPage: React.FC = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<UserRequestResetSchema>();
  const { mutate: requestReset, isPending, isError, error, isSuccess } = useRequestResetMutation();

  // Handle form submission
  const onSubmit: SubmitHandler<UserRequestResetSchema> = (data) => {
    requestReset(data);
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" gutterBottom>
        Reset password
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <TextField
          label="Email"
          variant="outlined"
          {...register('email', { required: 'Email is required' })}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        {isError && (
          <Alert severity="error">{getErrorMessage(error)}</Alert>
        )}
        {isSuccess && (
          <Alert severity="info">
            Password reset request sent. Check your email for further instructions.
          </Alert>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isPending}
        >
          {isPending ? <CircularProgress size={24} /> : 'Request password reset'}
        </Button>
        {/* Add link to reset password page */}
        <Typography align="right" variant="body2" sx={{ marginTop: 0 }}>
          Remember your password? {' '}
          <Link href="/login" underline="hover">
            Go to login page.
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default PasswordResetRequestPage;