import React from 'react';
import { Modal, Box, TextField, Button, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { getErrorMessage } from '../../utils/utils';
import { QreateCreateSchema, QrType } from '../../schemas/qreate';
import { useCreateQreate } from '../../queries/qreate';

interface QreateCreateModalProps {
  open: boolean;
  handleClose: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const QreateCreateModal: React.FC<QreateCreateModalProps> = ({ open, handleClose }) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm<QreateCreateSchema>();
  const { mutate: createQreate, isPending, isError, error } = useCreateQreate();

  const onSubmit: SubmitHandler<QreateCreateSchema> = (data) => {
    createQreate(data, {
      onSuccess: () => {
        reset();
        handleClose();    // Close the modal
      },
      onError: (error) => {
        console.error('Error creating Qreate:', getErrorMessage(error));
      },
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h6" component="h2" gutterBottom>
          Add New Qreate
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <TextField
            label="Title"
            variant="outlined"
            {...register('title', { required: 'Title is required' })}
            error={!!errors.title}
            helperText={errors.title?.message}
          />
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="qr_type-label">Type</InputLabel>
            <Select
              labelId="qr_type-label"
              {...register('qr_type', { required: 'Type is required' })}
              label="Type"
            >
              {Object.values(QrType).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isError && <Typography color="error">{getErrorMessage(error)}</Typography>}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isPending}  // Disable button while loading
          >
            {isPending ? 'Creating...' : 'Create'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default QreateCreateModal;