import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography, InputAdornment, IconButton } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useForm, SubmitHandler } from 'react-hook-form';
import { MediaCreateSchema } from '../../schemas/media';
import { useCreateMedia, useGetMediaExtensions } from '../../queries/media'; // Add useGetMediaExtensions
import { getErrorMessage } from '../../utils/utils';

interface MediaCreateModalProps {
  open: boolean;
  handleClose: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const MediaCreateModal: React.FC<MediaCreateModalProps> = ({ open, handleClose }) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm<MediaCreateSchema>();
  const { mutate: createMedia, isPending, isError, error } = useCreateMedia();

  // Fetch allowed file extensions
  const { data: mediaExtensions } = useGetMediaExtensions();

  // Local state for handling file input
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const [fileError, setFileError] = useState<string | null>(null); // Error state for invalid file type

  const onSubmit: SubmitHandler<MediaCreateSchema> = (data) => {
    if (!selectedFile) {
      alert("Please upload a file");
      return;
    }

    const mediaData: MediaCreateSchema = {
      ...data,
      file: selectedFile,
    };

    createMedia(mediaData, {
      onSuccess: () => {
        reset();
        setSelectedFile(null);
        setFileName('');  // Clear the file name on success
        setFileError(null); // Clear the file error
        handleClose();    // Close the modal
      },
      onError: (error) => {
        console.error('Error creating media:', error);
      },
    });
  };

  // Handle file selection
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file.name.split('.').pop()?.toLowerCase(); // Extract file extension

      // Validate file extension
      if (mediaExtensions && fileExtension) {
        const allValidExtensions = [
          ...mediaExtensions.video,
          ...mediaExtensions.image,
          ...mediaExtensions.audio,
          ...mediaExtensions.document,
        ];

        if (!allValidExtensions.includes(fileExtension)) {
          setFileError(`Invalid file type. Allowed types: ${allValidExtensions.join(', ')}`);
          setSelectedFile(null); // Clear selected file if invalid
          setFileName(''); // Clear file name if invalid
          return;
        }
      }

      // Validate file size
      if (file.size > 10 * 1024 * 1024) {
        setFileError('File size exceeds the limit of 10MB');
        setSelectedFile(null); // Clear selected file if invalid
        setFileName(''); // Clear file name if invalid
        return;
      }

      // If the file type is valid, update the state
      setFileError(null);
      setSelectedFile(file);
      setFileName(file.name);  // Store the selected file's name
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h6" component="h2" gutterBottom>
          Add New Media
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <TextField
            label="Title"
            variant="outlined"
            {...register('title', { required: 'Title is required' })}
            error={!!errors.title}
            helperText={errors.title?.message}
          />
          <TextField
            label="Description"
            variant="outlined"
            {...register('description')}
          />

          {/* File upload input */}
          <TextField
            label="Upload File"
            variant="outlined"
            value={fileName} // Display the file name
            InputLabelProps={{
              shrink: true, // Keep label up when there's a value
            }}
            InputProps={{
              readOnly: true, // Make input read-only for user
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton component="label">
                    <UploadFileIcon />
                    <input
                      type="file"
                      hidden
                      onChange={handleFileChange} // Handle file selection
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* Display file type error */}
          {fileError && <Typography color="error">{fileError}</Typography>}
          {errors.file && <Typography color="error">{errors.file.message}</Typography>}
          {isError && <Typography color="error">{getErrorMessage(error)}</Typography>}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isPending}  // Disable button while loading
          >
            {isPending ? 'Creating...' : 'Create'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MediaCreateModal;