import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  CircularProgress,
  Typography,
  Alert,
} from '@mui/material';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { useIsAuthenticatedQuery } from '../../queries/auth';
import { useGetAllMedia } from '../../queries/media'; // Adjust the import path as needed
import { MediaResultSchema } from '../../schemas/media';
import CustomToolbar from './media.toolbar';


const MediaOverview: React.FC = () => {
  const navigate = useNavigate();
  const { data: isAuthenticated, isLoading: isAuthLoading } = useIsAuthenticatedQuery();
  const { data: media, isLoading: isMediaLoading, isError, error } = useGetAllMedia(); // Fetch media items
  const [mediaRows, setMediaRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    if (!isAuthLoading) {
      if (!isAuthenticated) {
        navigate('/login');
      }
    }
  }, [isAuthenticated, isAuthLoading, navigate]);

  useEffect(() => {
    if (!isMediaLoading && media) {
      setMediaRows(media.map((item: MediaResultSchema) => ({
        id: item.uuid,
        title: item.title,
        description: item.description || 'N/A',
        file_extension: item.file_extension,
      })));
    }
  }, [media, isMediaLoading]);

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title', width: 200 },
    { field: 'description', headerName: 'Description', width: 250 },
    { field: 'file_extension', headerName: 'File Extension', width: 150 },
  ];

  if (isAuthLoading || isMediaLoading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        My media
      </Typography>
      {isError && <Alert severity="error">{error.message}</Alert>}
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={mediaRows}
          columns={columns}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    </Container>
  );
};

export default MediaOverview;