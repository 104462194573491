import React, { useEffect } from 'react';
import { Box, TextField, Button, Typography, FormControl, InputLabel, Select, MenuItem, Alert, FormControlLabel, Switch } from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form'; // Import getValues
import { useParams } from 'react-router-dom';
import { QreateContentsResultSchema, QreateUpdateSchema, QrStatus } from '../../schemas/qreate';
import { useGetQreate, useGetQreateContents, useUpdateQreate } from '../../queries/qreate';
import { getErrorMessage } from '../../utils/utils';

const QreateUpdatePage: React.FC = () => {
  const { qrCode } = useParams<{ qrCode: string }>(); // Get the ID from the URL

  const { data: contentList } = useGetQreateContents(qrCode); // Fetch content for dropdown
  const { data: qreate, isLoading: isQreateLoading } = useGetQreate(qrCode); // Fetch the qreate data

  const { register, handleSubmit, formState: { errors, isDirty, dirtyFields }, reset, watch, control } = useForm<QreateUpdateSchema>();
  const { mutate: updateQreate, isPending, isError, error, isSuccess } = useUpdateQreate(qrCode); // Mutation for updating qreate

  useEffect(() => {
    if (qreate) {
      const resetData = {
        title: qreate.title || '', // Fallback to empty string if null
        content_uuid: qreate.content_uuid || '', // Fallback to empty string if undefined
        status: qreate.status || '', // Provide a default value
        passcode_protect: qreate.is_locked, // Provide a default value
      };
      reset(resetData);
    }
  }, [qreate, reset]);

  // Function to get only the changed fields
  const getChangedFields = (): Partial<QreateUpdateSchema> => {
    const currentValues = watch();
    const changedFields: Partial<QreateUpdateSchema> = {};

    // Iterate over dirty fields to collect only changed values
    for (const field of Object.keys(dirtyFields)) {
      const typedField = field as keyof QreateUpdateSchema;
      // @ts-ignore
      changedFields[typedField] = currentValues[typedField];
    }

    return changedFields;
  };


  const onSubmit: SubmitHandler<QreateUpdateSchema> = (data) => {
    // Scroll up
    window.scrollTo(0, 0);
    const changedFields = getChangedFields();
    const newFields = watch();
    newFields.passcode = ''; // Remove passcode from newFields
    newFields.passcode_confirm = ''; // Remove passcode_confirm from newFields

    updateQreate(changedFields, {
      onSuccess: () => reset(newFields), // Reset the form on success
    });
  };

  if (isQreateLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Edit Qreate
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        {isSuccess && <Alert severity="info" sx={{ mb: 2 }}>Qreate updated successfully!</Alert>}
        {isError && <Alert severity="error">{getErrorMessage(error)}</Alert>}

        {/* Editable Fields */}
        <TextField
          label="Title"
          variant="outlined"
          {...register('title')}
          error={!!errors.title}
          helperText={errors.title?.message}
        />
        <FormControl fullWidth variant="outlined">
          <InputLabel id="media-label">Content</InputLabel>
          <Select
            labelId="content-label"
            {...register('content_uuid')}
            label="Media"
            defaultValue={qreate?.content_uuid || ""} // Ensure initial value is set
          >
            {contentList?.map((content: QreateContentsResultSchema) => (
              <MenuItem key={content.uuid} value={content.uuid}>
                {content.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            {...register('status')}
            label="Status"
            defaultValue={qreate?.status || ""} // Ensure initial value is set
          >
            {Object.values(QrStatus).map((status) => (
              <MenuItem key={status} value={status}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="QR Code"
          variant="outlined"
          value={qreate?.qr_code || ''}
          disabled
        />
        <TextField
          label="QR Type"
          variant="outlined"
          value={qreate?.qr_type || ''}
          disabled
        />
        <Controller
          name="passcode_protect"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Switch {...field} checked={field.value ? true : false} />}
              label="Passcode Protect"
            />
          )}
        />
        <TextField
          label="Passcode"
          variant="outlined"
          type="password"
          {...register('passcode')}
          error={!!errors.passcode}
          helperText={errors.passcode?.message}
          disabled={!watch('passcode_protect')}
        />
        <TextField
          label="Confirm Passcode"
          variant="outlined"
          type="password"
          {...register('passcode_confirm')}
          error={!!errors.passcode_confirm}
          helperText={errors.passcode_confirm?.message}
          disabled={!watch('passcode_protect')}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isPending || !isDirty} // Disable button while loading or if no changes
        >
          {isPending ? 'Updating...' : 'Update'}
        </Button>
      </Box>
    </Box>
  );
};

export default QreateUpdatePage;