import { useLogoutMutation } from '../queries/auth';

export const useLogout = () => {
  const { mutate: logoutMutation } = useLogoutMutation();

  const logout = () => {
    logoutMutation();
  };

  return { logout };
};