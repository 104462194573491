import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, Divider, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticatedQuery } from '../../queries/auth';
import { useLogout } from '../../hooks/useLogout';

const TopNavBar: React.FC = () => {
  const { data: isAuthenticated } = useIsAuthenticatedQuery();
  const { logout } = useLogout();

  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Function to go back to the previous page
  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  // Function to toggle the drawer state
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  // Function to handle navigation when a list item is clicked
  const handleNavigation = (path: string) => {
    navigate(path);
    setIsDrawerOpen(false); // Close the drawer after navigation
  };

  const handleLogout = () => {
    logout();
    setIsDrawerOpen(false); // Close the drawer after logout
  }


  const MenuOptions = () => {
    if (isAuthenticated) {
      return (
        <>
          <MenuItem onClick={() => handleNavigation('/qreate')}>My qreates</MenuItem>
          <MenuItem onClick={() => handleNavigation('/media')}>My media files</MenuItem>
          <MenuItem onClick={() => handleNavigation('/feedback')}>My feedback forms</MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </>
      );
    } else {
      return (
        <>
          <MenuItem onClick={() => handleNavigation('/login')}>Login</MenuItem>
          <MenuItem onClick={() => handleNavigation('/register')}>Register</MenuItem>
        </>
      );
    }
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {/* Return Button */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            sx={{ mr: 2 }}
            onClick={handleBackClick}
          >
            <ArrowBackIcon />
          </IconButton>

          {/* Title in the center */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} onClick={() => handleNavigation('/')}>
            Qreate
          </Typography>

          {/* Hamburger Menu Button */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer for Hamburger Menu */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
      >
        <MenuOptions />
      </Drawer>
    </>
  );
};

export default TopNavBar;