import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import client from './client'; // Import your axios client
import { MediaCreateSchema, MediaExtensionResultSchema, MediaResultSchema } from '../schemas/media';

export const useGetMediaExtensions = () => {
  return useQuery({
    queryKey: ['mediaExtensions'],
    queryFn: async () => {
      const response = await client.get<MediaExtensionResultSchema>('/media/extensions');
      return response.data;
    },
  });
}

export const useGetAllMedia = () => {
  return useQuery({
    queryKey: ['media'],
    queryFn: async () => {
      const response = await client.get<MediaResultSchema[]>('/media');
      return response.data;
    },
  });
};

export const useGetMedia = (uuid: string) => {
  return useQuery({
    queryKey: ['media', uuid],
    queryFn: async () => {
      const response = await client.get<MediaResultSchema>(`/media/${uuid}`);
      return response.data;
    },
  });
};

export const useCreateMedia = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (media: MediaCreateSchema) => {
      const formData = new FormData();
      formData.append('title', media.title);
      formData.append('description', media.description || '');
      formData.append('file', media.file);

      const response = await client.post<MediaResultSchema>('/media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    },
    onSuccess: (newMedia) => {
      // Update the cache for the ['media'] query with the new media item
      queryClient.setQueryData<MediaResultSchema[]>(['media'], (oldMedia) => {
        return oldMedia ? [...oldMedia, newMedia] : [newMedia];
      });
    },
  });
};