import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Container, TextField, Typography, Box, Button, Rating, Paper, Alert } from '@mui/material';
import { useGetQreateFeedback } from '../../../queries/qreate';
import ContentPageWrapper from '../contentWrapper';
import { ContentPageProps } from '../schemas';
import { FeedbackResponseCreateSchema } from '../../../schemas/feedback';
import { useCreateFeedbackResponse } from '../../../queries/feedback';

const ContentFeedbackPage: React.FC<ContentPageProps> = ({ qrCode }) => {
  const { data: qreate, isError, error, isLoading } = useGetQreateFeedback(qrCode);
  const { mutate: createFeedbackResponse, isError: createIsError, error: createError, isSuccess: createSuccess } = useCreateFeedbackResponse(qreate?.content_uuid);

  const { handleSubmit, control, formState: { errors } } = useForm<FeedbackResponseCreateSchema>({
    defaultValues: {
      score: 0,
      name: '',
      comment: ''
    }
  });

  const onSubmit = (data: FeedbackResponseCreateSchema) => {
    createFeedbackResponse(data);
  };

  const ErrorMessage = () => {
    if (createIsError) {
      console.log('createError', createError);
      return (
        <Alert color="error">Could not save your feedback, please try again later.</Alert>
      );
    }
    return null;
  }

  const SuccessMessage = () => {
    if (createSuccess) {
      return (
        <Alert color="success">Feedback saved successfully! You can now close this page.</Alert>
      );
    }
    return null;
  }

  return (
    <ContentPageWrapper
      isLoading={isLoading}
      isError={isError}
      error={error}
      qrCode={qrCode}
    >
      <Container maxWidth="sm" sx={{ mt: 2 }}>
        <Paper elevation={3} sx={{ padding: 3, borderRadius: 4 }}>
          <Box mb={4}>
            <Typography variant="h4" gutterBottom>
              {qreate?.content?.title}
            </Typography>
            {qreate?.content?.description && (
              <Typography variant="body1">
                {qreate.content.description}
              </Typography>
            )}
          </Box>

          {/* Form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Rating Input */}
            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                Rating
              </Typography>
              <Controller
                name="score"
                control={control}
                rules={{ required: 'Score is required' }}
                render={({ field }) => (
                  <Rating
                    {...field}
                    value={Number(field.value)}
                    onChange={(_, value) => field.onChange(value)}
                    precision={0.5}
                  />
                )}
              />
              {errors.score && (
                <Typography color="error">{errors.score.message}</Typography>
              )}
            </Box>

            {/* Name Input */}
            <Box mb={3}>
              <Controller
                name="name"
                control={control}
                rules={{ required: 'Name is required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Name"
                    variant="outlined"
                    error={!!errors.name}
                    helperText={errors.name ? errors.name.message : ''}
                  />
                )}
              />
            </Box>

            {/* Comment Input */}
            <Box mb={3}>
              <Controller
                name="comment"
                control={control}
                rules={{ required: 'Comment is required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Comment"
                    variant="outlined"
                    multiline
                    rows={4}
                    error={!!errors.comment}
                    helperText={errors.comment ? errors.comment.message : ''}
                  />
                )}
              />
            </Box>

            {/* Submit Button */}
            <Box textAlign="center" mb={2}>
              <Button type="submit" variant="contained" color="primary" disabled={createSuccess}>
                Submit Feedback
              </Button>
            </Box>
          </form>
          <SuccessMessage />
          <ErrorMessage />
        </Paper>
      </Container>
    </ContentPageWrapper>
  );
};

export default ContentFeedbackPage;