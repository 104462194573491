import React from 'react';
import ReactPlayer from 'react-player'

import { Alert } from '@mui/material';
import { useGetQreateVideo } from '../../../queries/qreate';
import ContentPageWrapper from '../contentWrapper';
import { ContentPageProps } from '../schemas';

const ContentPageVideo: React.FC<ContentPageProps> = ({ qrCode }) => {
  const { data: qreate, isError, error, isLoading } = useGetQreateVideo(qrCode);

  return (
    <ContentPageWrapper
      title={qreate?.title}
      isLoading={isLoading}
      isError={isError}
      error={error}
      qrCode={qrCode}
    >
      {qreate?.content?.url ? (
        <ReactPlayer url={qreate?.content?.url} width="100%" controls />
      ) : (
        <Alert severity="info">No media file available for display.</Alert>
      )}
    </ContentPageWrapper>
  );
};

export default ContentPageVideo;